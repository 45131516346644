import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel, IHttpConnectionOptions, HttpTransportType } from '@microsoft/signalr';
import { AuthService } from '@mt-ng2/auth-module';
import { environment } from '@environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SignalRService {
    constructor(private authService: AuthService) {}

    startConnection(hubEndpoint: string): Observable<HubConnection> {
        return new Observable<HubConnection>((observer) => {
            this.authService.getToken().subscribe((result) => {
            const options: IHttpConnectionOptions = {
                accessTokenFactory: () => (result && result.token !== '' ? result.token : ''),
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            }
            const connection = new HubConnectionBuilder()
                .configureLogging(LogLevel.Error)
                .withUrl(environment.baseApiUrl + hubEndpoint, options)
                .build();

            connection.start()
                .then(() => observer.next(connection))
                // eslint-disable-next-line no-console
                .catch((err) => console.error(err.toString()));
            });
        });
    }

    stopConnection(connection: HubConnection): void {
        connection.stop()
            // eslint-disable-next-line no-console
            .catch((err) => console.error(err.toString()));
    }
}
