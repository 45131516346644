import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IEntity, VirtualScrollEntityListComponent } from '@mt-ng2/entity-list-module';
import { ExtendedEntityListConfig } from './extended-entity-list.config';
import { EntityListDragDropHelper } from './libraries/entity-list-drag-drop-helper.library';
import { provideTranslocoScope } from '@jsverse/transloco';

export interface IItemMovedEvent {
    sourceItem: IEntity;
    targetItem: IEntity;
    newIndex: number;
    $event: DragEvent;
}

export interface IItemMoveToTopEvent {
    item: IEntity;
    currentItems: IEntity[];
}

export interface IItemMoveToBottomEvent {
    item: IEntity;
    currentItems: IEntity[];
}

@Component({
    selector: 'app-extended-entity-list',
    templateUrl: './extended-entity-list.component.html',
    styles: [`
        .draggable-item {
            cursor: grab;
        }
        
        .draggable-item:active {
            cursor: grabbing;
        }

        .drop-zone {
            border-bottom: 2px solid #0066cc;
        }

        .drag-handle {
            color: #ccc;
            text-align: center;
        }

        .drag-handle:hover {
            color: #666;
        }
    `],
    providers: [
        provideTranslocoScope(
            {
                scope: 'common',
                alias: 'c',
            }
        ),
    ],
})
export class ExtendedEntityListComponent extends VirtualScrollEntityListComponent {
    dragDropHelper?: EntityListDragDropHelper;
    
    @Output() itemMovedEvent = new EventEmitter<IItemMovedEvent>();
    @Output() moveToTopEvent = new EventEmitter<IItemMoveToTopEvent>();
    @Output() moveToBottomEvent = new EventEmitter<IItemMoveToBottomEvent>();

    protected reorderItems(sourceItem: IEntity, targetItem: IEntity, newIndex: number) {
        const currentItems = this.items();
        const sourceIndex = currentItems.findIndex(item => item === sourceItem);
        
        // Create a new array with the reordered items
        const newItems = [...currentItems];
        
        // Remove the item from its original position
        newItems.splice(sourceIndex, 1);
        
        // Insert the item at its new position
        newItems.splice(newIndex, 0, sourceItem);
        
        // Update the items signal
        this.items.set(newItems);
    }

    @Input()
    override set entityListConfig(value: ExtendedEntityListConfig) {
        if (value) {
            // Initialize drag-drop functionality if enabled
            if (value.dragDrop?.enabled) {
                this.dragDropHelper = new EntityListDragDropHelper(
                    value.dragDrop,
                    this.items
                );
            }
            
            super.entityListConfig = value;
        }
    }

    override get entityListConfig(): ExtendedEntityListConfig {
        return super.entityListConfig as ExtendedEntityListConfig;
    }

    onDragStart(item: IEntity, event: DragEvent): void {
        this.dragDropHelper?.handleDragStart(item, event);
    }

    onDragOver(event: DragEvent): void {
        this.dragDropHelper?.handleDragOver(event);
    }

    onDragEnter(event: DragEvent): void {
        this.dragDropHelper?.handleDragEnter(event);
    }

    onDragLeave(event: DragEvent): void {
        this.dragDropHelper?.handleDragLeave(event);
    }

    onDrop(event: DragEvent, targetItem: IEntity): void {
        const result = this.dragDropHelper?.handleDrop(event, targetItem);
        if (result) {
            this.reorderItems(result.sourceItem, result.targetItem, result.newIndex);
            
            this.itemMovedEvent.emit({
                ...result,
                $event: event
            });
        }
    }

    onDragEnd(): void {
        this.dragDropHelper?.handleDragEnd();
    }

    moveToTop(item: IEntity) {
        this.moveToTopEvent.emit({
            item,
            currentItems: this.items()
        });
    }

    moveToBottom(item: IEntity) {
        this.moveToBottomEvent.emit({
            item,
            currentItems: this.items()
        });
    }
}
