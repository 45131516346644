import moment from 'moment-timezone';

export const getUserTimezone = () => {
    return moment.tz.guess(true);
};

export const getAllTimezones = () => {
    return moment.tz.names();
};

export const formatTime = (milliseconds: number) => {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
}
