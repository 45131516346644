<div class="row text-center">
    <div class="countdown-container" *ngIf="!showBegin">
        <div class="countdown-light" *ngFor="let light of lights; let i = index" [ngClass]="{'active': light.active}"
            [style.background-color]="light.active ? light.color : '#eee'"
            [style.border-color]="light.active ? light.color : '#ccc'">
        </div>
    </div>

    <div class="begin-text" *ngIf="showBegin">
        {{ beginText }}
    </div>

    <button type="button" *ngIf="showStartButton" (click)="startCountdown()" [disabled]="isCountingDown">
        {{ startButtonText }}
    </button>
</div>