import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, HostListener  } from '@angular/core';
import { Subscription} from 'rxjs';
import { TimerService } from '../services/timer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RaceCountdownComponent } from '@tournament-portal-common/components/race-countdown/race-countdown.component';
import { TimerProgress, TimerEvent, TimerState } from './../../common/components/timer/timer.component';
import { provideTranslocoScope } from '@jsverse/transloco';
import { SoundService } from '@tournament-portal-common/services/sound.service';
import { formatTime } from '@common/libraries/timezone.library';
import { AuthService } from '@mt-ng2/auth-module';
import { EventService } from '../../common/services/event.service';
import { IEvent } from '@model/interfaces/event';
import { environment } from '@environment';

@Component({
    selector: 'app-timer-display',
    templateUrl: './timer-display.component.html',
    styleUrls: ['./timer-display.component.less'],
    providers: [
        provideTranslocoScope(
            {
                scope: 'tournament-portal/timer-display',
                alias: 'tp.td',
            },
            {
                scope: 'tournament-portal',
                alias: 'tp',
            }
        ),
    ],
})
export class TimerDisplayComponent implements OnInit, AfterViewInit {
    @ViewChild('raceCountdown') raceCountdown!: RaceCountdownComponent;

    eventId: number;
    event: IEvent;
    entityId: string;
    timerSub: Subscription;
    loading = true;
    lessThanTenSeconds = false;
    showRaceCountdown = false;
    durationSeconds: number;
    durationMilliseconds: number;
    logoFull = `${environment.assetsPath}logo-full.png`;
    programImagePath: string;
    timerState: TimerState = null;
    timerStates = TimerState;

    get timerIsComplete() {
        return this.durationMilliseconds <= 0 || this.timerState === TimerState.EndedEarly;
    }

    constructor(
        private timerService: TimerService,
        private router: Router,
        private route: ActivatedRoute,
        private soundService: SoundService,
        private authService: AuthService,
        private eventService: EventService,
        private el: ElementRef
    ) { }

    ngOnInit(): void {
        this.eventId = this.authService.currentUser.value.CustomOptions.EventId as number;
        if (!this.eventId) {
            void this.router.navigate(['/tournament/displays/timer-display-config']);
            return;
        }

        this.entityId = this.route.snapshot.queryParams['entityId'];
        if (!this.entityId) {
            void this.router.navigate(['/tournament/displays/timer-display-config']);
            return;
        }

        this.eventService.getById(this.eventId).subscribe(event => {
            this.event = event;
            this.durationSeconds = this.route.snapshot.queryParams['timeLimit'];
            this.durationMilliseconds = this.durationSeconds * 1000;
            this.stageTimer();

            this.programImagePath = environment.assetsPath + 'images/noimage.png';
            const programId: number = event.Season?.ProgramId ?? 0;
            if (programId > 0) {
                this.programImagePath = this.getProgramImageUrl(programId);
            }
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.setDivHeight();
    }

    setDivHeight() {
        const windowHeight = window.innerHeight;
        if (windowHeight) {
            const headerHeight = windowHeight * 0.15;
            this.el.nativeElement.querySelector('.screen-header').style.height = `${headerHeight}px`;
            const divHeight = window.innerHeight - headerHeight;
            this.el.nativeElement.querySelector('.screen-content').style.height = `${divHeight}px`;
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.setDivHeight();
        }, 0);
    }

    getProgramImageUrl(programId: number): string {
        return `${environment.baseApiUrl}/api/v1/programs/${programId}/pic`;
    }

    stageTimer(): void {
        this.timerService.connectTimerDisplay(this.entityId);
        this.timerSub = this.timerService.timerChanges(this.entityId)
            .subscribe(timer => {
                if (!timer) {
                    this.loading = false;
                    return;
                }

                this.timerState = timer.timerState;
                if (this.timerState !== TimerState.Started) {
                    this.showRaceCountdown = false;
                }

                this.loading = false;
            });
    }

    raceCountdownOnFinish() {
        this.showRaceCountdown = false;
    }

    onProgressUpdate(progress: TimerProgress) {
        if (this.entityId !== progress.entityId)
            return;

        if (progress.percentage >= 100 && !this.showRaceCountdown && this.timerState === TimerState.Started) {
            this.showRaceCountdown = true;
            setTimeout(() => {
                this.raceCountdown?.startCountdown();
            }, 1000);
            return;
        }

        if (progress.remainingMs <= 10000) {
            this.lessThanTenSeconds = true;
        } else {
            this.lessThanTenSeconds = false;
        }
    }

    onTimerStopped(event: TimerEvent) {
        if (this.entityId !== event.entityId)
            return;

        if (event.timer?.durationMilliseconds <= 0)
            this.onTimerComplete(event);
    }

    onTimerReset(event: TimerEvent) {
        if (this.entityId !== event.entityId)
            return;

        this.durationMilliseconds = event.timer.durationMilliseconds;
    }

    onTimerComplete(event: TimerEvent) {
        if (this.entityId !== event.entityId)
            return;

        this.durationMilliseconds = 0;
        this.soundService.playSuccess();
    }

    formatTime(milliseconds: number) {
        return formatTime(milliseconds);
    }
}
