import { filter, map, Subscription } from 'rxjs';
import { TranslocoService } from '@jsverse/transloco';

export function loadBasicTranslation(subscriptions: Subscription, translocoService: TranslocoService): void {
        subscriptions.add(
            translocoService.events$
                .pipe(
                    filter((e) => e.type === 'translationLoadSuccess'),
                    map((e) => e?.payload),
                ).subscribe()
        );
    }
