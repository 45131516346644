import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IEventSkillsChallenge } from '@model/interfaces/event-skills-challenge';
import { Observable } from 'rxjs';
import { IEventField } from '@model/interfaces/event-field';

export const emptyEventSkillsChallenge: IEventSkillsChallenge = {
    EndTime: new Date(),
    EventId: null,
    EventFieldId: null,
    Id: 0,
    Score: 0,
    StartTime: new Date(),
    TeamId: null,
    EventSkillsChallengeTypeId: null,
};

@Injectable({
    providedIn: 'root',
})
export class EventSkillsChallengeService extends BaseService<IEventSkillsChallenge> {
    constructor(public http: HttpClient) {
        super('/event-skills-challenges', http, null, { entityName: 'Event Skills Challenge' });
    }

    formatTitleText(entity: IEventSkillsChallenge): void {
        this.setTitle(`Event Skills Challenge: ${entity.Id}`);
    }

    getEmptyEventSkillsChallenge(): IEventSkillsChallenge {
        return { ...emptyEventSkillsChallenge };
    }

    getEventFields(eventId: number): Observable<IEventField[]> {
        return this.http.get<IEventField[]>(`/event-skills-challenges/${eventId}/get-event-fields`);
    }
}
