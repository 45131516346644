import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    inject,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { loadBasicTranslation } from '@common/libraries/transloco.library';
import { environment } from '@environment';
import { provideTranslocoScope, TranslocoService } from '@jsverse/transloco';
import { EventImageTypes } from '@model/enums/event-image-types.enum';
import { EventMatchStatuses } from '@model/enums/event-match-statuses.enum';
import { IEventMatchDisplay, IEventMatchList } from '@model/interfaces/custom/event-match-display';
import { IEventMatchScreenPagination } from '@model/interfaces/custom/event-match-screen-pagination';
import { IEvent } from '@model/interfaces/event';
import { IEventImage } from '@model/interfaces/event-image';
import { ITeam } from '@model/interfaces/team';
import { AuthService } from '@mt-ng2/auth-module';
import { ModalService } from '@mt-ng2/modal-module';
import { forkJoin, interval, Observable, Subscription, tap } from 'rxjs';

import { EventService } from '../../../admin-portal/events/services/event.service';
import { EventMatchService } from '../../common/services/event-match.service';

@Component({
    selector: 'app-match-schedule-screen',
    styleUrls: ['./match-schedule-screen.component.less'],
    templateUrl: './match-schedule-screen.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('listAnimation', [
            transition('* => *', [
                query(
                    ':enter',
                    [
                        style({ opacity: 0, transform: 'translateY(-10px)' }),
                        stagger('100ms', [animate('500ms ease-in-out', style({ opacity: 1, transform: 'translateY(0)' }))]),
                    ],
                    { optional: true },
                ),
            ]),
        ]),
    ],
    providers: [
        provideTranslocoScope({
            scope: 'event-matches',
            alias: 'e',
        }),
    ],
})
export class MatchScheduleScreenComponent implements OnInit, AfterViewInit, OnDestroy {
    eventName: string;
    screenTitle: string = 'MATCH SCHEDULE' as string;
    programImagePath: string;
    event: IEvent;
    divHeight: number;
    divHeightHeader: number;
    divHeightRow: number;
    eventMatches: IEventMatchDisplay;
    eventMatchLists: IEventMatchList[];
    teams: ITeam[] = [];
    isLoading = false;
    eventId: number;
    currentPage = 1;
    itemsPerPage = 10;
    total: number;
    totalPages: number;
    subscriptions = new Subscription();
    logoFull = `${environment.assetsPath}logo-full.png`;
    canceledMatch: number;
    selectedDate: Date;
    listCount = 0;
    displayLogo = false;
    eventImages: IEventImage[];
    eventSponsorImages: string[] = [];
    eventSponsorImagesToDisplay: string[] = [];
    sponsorImagesPerPage = 8;
    sponsorImagesCurrentPage = 1;
    sponsorImagesTotalPages: number;

    private _authService = inject(AuthService);
    private _eventService = inject(EventService);
    private _eventMatchService = inject(EventMatchService);
    private _el = inject(ElementRef);
    private _router = inject(Router);
    private _route = inject(ActivatedRoute);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    private _translocoService = inject(TranslocoService);

    ngOnInit(): void {
        this.eventId = this._authService.currentUser.value.CustomOptions.EventId;
        if (this.eventId === 0) {
            void this._router.navigate(['/portal/events']);
        }

        loadBasicTranslation(this.subscriptions, this._translocoService);
        this.canceledMatch = EventMatchStatuses.Canceled;
        this._route.queryParams.subscribe((params) => {
            this.selectedDate = params?.date ? new Date(params.date as Date) : null;
            forkJoin([this._eventService.getById(this.eventId), this.getMatches()]).subscribe(
                ([event,]) => {
                    this.event = event;
                    this.eventName = event.Name;
                    this.eventImages = event.EventImages.filter((e) => e.EventImageTypeId === EventImageTypes.SponsorImage);
                    if (this.eventImages) {
                        this.getSponsorImages();
                    }
                    this.programImagePath = environment.assetsPath + 'images/noimage.png';
                    const programId: number = event.Season?.ProgramId ?? 0;
                    if (programId > 0) {
                        this.programImagePath = this.getProgramImageUrl(programId);
                    }
                    this.setTotalPages();
                    this.subscriptions.add(
                        interval(10000).subscribe(() => {
                            this.loadMatchList();
                        })
                    );
                },
            );
        });
    }

    getSearchParams(): IEventMatchScreenPagination {
        const eventPagination: IEventMatchScreenPagination = {
            EventId: this.eventId,
            Skip: (this.currentPage - 1) * this.itemsPerPage,
            Take: this.itemsPerPage,
            SelectedDate: this.selectedDate,
        };
        return eventPagination;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.setDivHeight();
        }, 0);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    loadMatchList() {
        let loadList = true;
        this.sponsorImagesTotalPages = Math.ceil(this.eventSponsorImages.length / this.sponsorImagesPerPage);
        if (this.currentPage === this.totalPages) {
            if (this.sponsorImagesTotalPages > 0) {
                this.displayLogo = true;
                if (this.sponsorImagesCurrentPage > this.sponsorImagesTotalPages) {
                    loadList = true;
                    this.currentPage = 0;
                    this.sponsorImagesCurrentPage = 1;
                } else {
                    loadList = false;
                    this.loadImageList();
                }
            } else {
                this.currentPage = 0;
            }
        }
        if (loadList) {
            this.displayLogo = false;
            if (this.total <= this.itemsPerPage) {
                this.currentPage = 0;
            } else {
                this.currentPage++;
            }
            this.getMatches().subscribe();
        }
    }

    getMatches(): Observable<IEventMatchDisplay> {
        const data = this.getSearchParams();
        return this._eventMatchService.getMatchesByEventId(data).pipe(
            tap((eventMatches) => {
                this.eventMatches = eventMatches;
                this.eventMatchLists = this.eventMatches?.EventMatchLists;
                this.total = this.eventMatches?.Total;
                this.listCount = this.eventMatchLists?.length ?? 0;
            })
        );
    }

    loadImageList(): void {
        const startIndex = this.sponsorImagesCurrentPage - 1;
        const indexFrom = (startIndex > 0 ? startIndex : 0) * this.sponsorImagesPerPage;
        const indexTo = indexFrom + this.sponsorImagesPerPage;
        this.eventSponsorImagesToDisplay = this.eventSponsorImages.slice(indexFrom, indexTo);
        this.sponsorImagesCurrentPage++;
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.setDivHeight();
    }

    setDivHeight() {
        const windowHeight = window.innerHeight;
        if (windowHeight) {
            const headerHeight = windowHeight * 0.2;
            this._el.nativeElement.querySelector('.screen-header').style.height = `${headerHeight}px`;
            const divHeight = window.innerHeight - headerHeight;
            const getHeaderHeight = divHeight * 0.1;
            this.divHeightHeader = getHeaderHeight;
            this.divHeightRow = (divHeight - this.divHeightHeader) / 10;
            this._el.nativeElement.querySelector('.screen-content').style.height = `${divHeight}px`;
        }
    }

    getProgramImageUrl(programId: number): string {
        return `${environment.baseApiUrl}/api/v1/programs/${programId}/pic`;
    }

    getEventImageUrl(eventImageId: number): string {
        return `${environment.baseApiUrl}/api/v1/events/${eventImageId}/pic`;
    }

    getTeamMatch(eventMatch: IEventMatchList) {
        const [teamOne, teamTwo, teamThree, teamFour] = eventMatch.Teams;
        const extraMatchInfo = eventMatch.MatchTeams;
        const teamOneSpan = this.createTeamSpan(teamOne, extraMatchInfo, 'red');
        const teamTwoSpan = this.createTeamSpan(teamTwo, extraMatchInfo, 'red');

        if (teamThree && teamFour) {
            const teamThreeSpan = this.createTeamSpan(teamThree, extraMatchInfo, 'blue');
            const teamFourSpan = this.createTeamSpan(teamFour, extraMatchInfo, 'blue');
            return `${teamOneSpan} and ${teamTwoSpan} vs ${teamThreeSpan} and ${teamFourSpan}`;
        } else {
            return `${teamOneSpan} vs ${teamTwoSpan}`;
        }
    }

    getMatchTime(eventMatch: IEventMatchList) {
        const date = new Date(eventMatch.MatchTime);
        return date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
        });
    }

    getSponsorImages(): void {
        this.eventImages.forEach((e) => {
            if (e.ImageId > 0) {
                const image = this.getEventImageUrl(e.Id);
                if (image) {
                    this.eventSponsorImages.push(image);
                }
            }
        });
    }

    private formatTeamName(team: ITeam, hasExtraMatch: boolean): string {
        const baseTeamName = `${team.Letter}${team.Number}`;
        return hasExtraMatch ? `${baseTeamName}*` : baseTeamName;
    }

    private createTeamSpan(team: ITeam, extraMatchInfo: Record<number, boolean>, color: 'red' | 'blue'): string {
        const teamName = this.formatTeamName(team, extraMatchInfo[team.Id]);
        return `<span class="screen-content-row-column-teams-vs--${color}">${teamName}</span>`;
    }

    private setTotalPages() {
        this.totalPages = Math.ceil(this.total / this.itemsPerPage);
    }
}
