import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit, ElementRef, HostListener, inject } from '@angular/core';
import { forkJoin, Subscription} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { RaceCountdownComponent } from '@tournament-portal-common/components/race-countdown/race-countdown.component';
import { AuthService } from '@mt-ng2/auth-module';
import { TimerProgress, TimerEvent, TimerState } from '../../common/components/timer/timer.component';
import { provideTranslocoScope, TranslocoService } from '@jsverse/transloco';
import { SoundService } from '@tournament-portal-common/services/sound.service';
import { IEventSkillsChallenge } from '@model/interfaces/event-skills-challenge';
import { EventSkillsChallengeService } from '../../event-skills-challenges/services/event-skills-challenge.service';
import { formatTime } from '@common/libraries/timezone.library';
import { EventService } from '../../../admin-portal/events/services/event.service';
import { environment } from '@environment';
import { ISkillsChallengeTimer, SkillsChallengeTimerService } from '../services/skills-challenge-timer.service';
import { EventSkillsChallengeTypes } from '@model/enums/event-skills-challenge-types.enum';
import { EventFieldService } from '../../event-skills-challenges/services/event-field.service';

@Component({
    selector: 'app-skills-challenge-display',
    templateUrl: './skills-challenge-display.component.html',
    styleUrls: ['./skills-challenge-display.component.less'],
    providers: [
        provideTranslocoScope(
            {
                scope: 'tournament-portal/skills-challenge-display',
                alias: 'tp.scd',
            },
            {
                scope: 'tournament-portal',
                alias: 'tp',
            }
        ),
    ],
})
export class SkillsChallengeDisplayComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('raceCountdown') raceCountdown!: RaceCountdownComponent;

    eventId: number;
    entityId: string;
    selectedEventSkillsChallengeTypeDisplay: string;
    hasActiveTimer = false;
    timerSub: Subscription = new Subscription();
    translationSub: Subscription = new Subscription();
    durationMilliseconds = 0;
    loading = false;
    selectedEventSkillsChallenge: IEventSkillsChallenge = null;
    lessThanTenSeconds = false;
    showRaceCountdown = false;
    selectedEventSkillChallengeId: number;
    selectedFieldId: number;
    logoFull = `${environment.assetsPath}logo-full.png`;
    programImagePath: string;
    teamNumber: string;
    currentTimer: ISkillsChallengeTimer;
    eventFieldName: string; 
    totalSkillChallenge: number;
    get timerIsComplete() {
        return this.durationMilliseconds <= 0 || this.currentTimer?.timerState === TimerState.EndedEarly;
    }

    private skillsChallengeTimerService = inject(SkillsChallengeTimerService);
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private authService = inject(AuthService);
    private soundService = inject(SoundService);
    private eventSkillsChallengeService = inject(EventSkillsChallengeService);
    private translocoService = inject(TranslocoService);
    private el = inject(ElementRef);
    private eventService = inject(EventService);
    private eventFieldService = inject(EventFieldService);

    raceCountdownOnFinish() {
        this.showRaceCountdown = false;
    }

    ngOnInit(): void {
        this.eventId = this.authService.currentUser.value.CustomOptions.EventId as number;
        if (!this.eventId) {
            void this.router.navigate(['/tournament/displays/skills-challenge-display-select']);
            return;
        }

        const eventIdParam = this.route.snapshot.queryParams['eventId'];
        const fieldIdParam = this.route.snapshot.queryParams['fieldId'];

        if (eventIdParam) {
            this.eventId = parseInt(eventIdParam as string, 10);
        }
        if (fieldIdParam) {
            this.selectedFieldId = parseInt(fieldIdParam as string, 10);
        }
        if (!this.selectedFieldId) {
            void this.router.navigate(['/tournament/displays/teamwork-display-select']);
            return;
        }
        forkJoin([
            this.eventService.getById(this.eventId),
            this.eventFieldService.getById(this.selectedFieldId)   
        ]).subscribe(([event, eventField]) => {
            this.programImagePath = environment.assetsPath + 'images/noimage.png';
            const programId: number = event.Season?.ProgramId ?? 0;
            if (programId > 0) {
                this.programImagePath = this.getProgramImageUrl(programId);
            }
            this.eventFieldName = eventField?.Name;
        });

        this.translationSub = new Subscription();
        this.skillsChallengeTimerService.connectTimerDisplay(this.eventId, this.selectedFieldId).subscribe(() => {
            this.skillsChallengeTimerService.tryGetFieldTimer(this.eventId, this.selectedFieldId);
            this.timerSub.add(
                this.skillsChallengeTimerService.fieldTimerChanges(this.eventId, this.selectedFieldId)
                    .subscribe((timers: ISkillsChallengeTimer[]) => {
                        this.currentTimer = timers
                            .sort(this.sortByTimerStateThenEndTime)
                            .find((timer) => new Date(timer.endTime) > new Date());
                        if (this.currentTimer) {
                            this.entityId = this.currentTimer.entityId;
                            this.durationMilliseconds = this.currentTimer.durationMilliseconds;
                            this.hasActiveTimer = this.currentTimer && !this.currentTimer.isPaused;
                            this.eventSkillsChallengeService.getById(parseInt(this.currentTimer.entityId))
                                .subscribe((eventSkillsChallenge) => {
                                    this.translateDisplayType(eventSkillsChallenge.EventSkillsChallengeTypeId);
                                });
                        }
                    })
            );
        })
    }

    sortByTimerStateThenEndTime = (a: ISkillsChallengeTimer, b: ISkillsChallengeTimer) => {
        if (a.timerState < b.timerState) return -1;
        if (a.timerState > b.timerState) return 1;
        if (new Date(b.endTime).getTime() < new Date(a.endTime).getTime()) return -1;
        if (new Date(b.endTime).getTime() > new Date(a.endTime).getTime()) return 1;

        return 0;
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.setDivHeight();
    }

    setDivHeight() {
        const windowHeight = window.innerHeight;
        if (windowHeight) {
            const headerHeight = windowHeight * 0.15;
            this.el.nativeElement.querySelector('.screen-header').style.height = `${headerHeight}px`;
            const divHeight = window.innerHeight - headerHeight;
            this.el.nativeElement.querySelector('.screen-content').style.height = `${divHeight}px`;
        }
    }

    getProgramImageUrl(programId: number): string {
        return `${environment.baseApiUrl}/api/v1/programs/${programId}/pic`;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.setDivHeight();
        }, 0);
    }

    ngOnDestroy(): void {
        this.timerSub.unsubscribe();
        this.translationSub.unsubscribe();
    }

    translateDisplayType(skillsChallengeTypeId: number): void {
        if (skillsChallengeTypeId === EventSkillsChallengeTypes.Piloting) {
            this.translationSub.add(
                this.translocoService.selectTranslate('Piloting', undefined, 'tournament-portal/skills-challenge-display').subscribe(
                    (value) => {
                        this.selectedEventSkillsChallengeTypeDisplay = value;
                    }
                )
            );
        } else if (skillsChallengeTypeId === EventSkillsChallengeTypes.Autonomous) {
            this.translationSub.add(
                this.translocoService.selectTranslate('Autonomous', undefined, 'tournament-portal/skills-challenge-display').subscribe(
                    (value) => {
                        this.selectedEventSkillsChallengeTypeDisplay = value;
                    }
                )
            );
        }
    }

    onProgressUpdate(progress: TimerProgress) {
        if (this.entityId !== progress.entityId)
            return;

        if (!this.hasActiveTimer)
            return;

        if (progress.percentage >= 100 && !this.showRaceCountdown) {
            this.showRaceCountdown = true;
            setTimeout(() => {
                this.raceCountdown.startCountdown();
            }, 1000);
            return;
        }

        this.lessThanTenSeconds = progress.remainingMs <= 10000;
    }

    onTimerStopped(event: TimerEvent) {
        if (this.entityId !== event.entityId)
            return;

        if (event.timer?.durationMilliseconds <= 0)
            this.onTimerComplete(event);
    }

    onTimerReset(event: TimerEvent) {
        if (this.entityId !== event.entityId)
            return;

        window.location.reload();
    }

    onTimerComplete(event: TimerEvent) {
        if (this.entityId !== event.entityId)
            return;

        this.soundService.playSuccess();
        this.durationMilliseconds = 0;
        this.loading = false;
        this.lessThanTenSeconds = false;
        this.showRaceCountdown = false;
        this.hasActiveTimer = false;
    }

    formatTime(milliseconds: number) {
        return formatTime(milliseconds);
    }
}
