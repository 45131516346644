import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SoundService } from '../../services/sound.service';

@Component({
    selector: 'app-race-countdown',
    templateUrl: 'race-countdown.component.html',
    styleUrls: ['race-countdown.component.less']
})
export class RaceCountdownComponent {
    @Input() lightInterval = 1000;  // Time between lights in ms
    @Input() beginText = 'BEGIN!';
    @Input() startButtonText = 'Start Countdown';
    @Input() showStartButton = true;
    @Input() beginTextDuration = 2000;  // How long 'BEGIN!' shows

    // Output events
    @Output() countdownStarted = new EventEmitter<void>();
    @Output() countdownFinished = new EventEmitter<void>();
    @Output() lightActivated = new EventEmitter<number>();

    lights = [
        { active: false, color: '#28a745' },  // Green
        { active: false, color: '#ffc107' },  // Yellow
        { active: false, color: '#dc3545' }   // Red
    ];
    isCountingDown = false;
    showBegin = false;

    constructor(private soundService: SoundService) { }

    public startCountdown() {
        if (this.isCountingDown) return;

        this.isCountingDown = true;
        this.showBegin = false;
        this.countdownStarted.emit();

        this.lights.forEach(light => light.active = false);
        this.lights[0].active = true;
        this.lightActivated.emit(0);
        this.soundService.playBeep(440, 400, 0.1);

        let currentLight = 1;
        const interval = setInterval(() => {
            if (currentLight < this.lights.length) {
                this.lights[currentLight].active = true;
                this.lightActivated.emit(currentLight);
                this.soundService.playBeep(440, 400, 0.1);
                currentLight++;
            } else {
                this.finishCountdown(interval);
            }
        }, this.lightInterval);
    }

    private finishCountdown(interval: NodeJS.Timeout | string | number | undefined) {
        clearInterval(interval);
        this.soundService.playSuccess();
        this.showBegin = true;

        setTimeout(() => {
            this.lights.forEach(light => light.active = false);
            this.isCountingDown = false;
            this.showBegin = false;
            this.countdownFinished.emit();
        }, this.beginTextDuration);
    }

    public reset() {
        this.lights.forEach(light => light.active = false);
        this.isCountingDown = false;
        this.showBegin = false;
    }
}