import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IEventField } from '@model/interfaces/event-field';

@Injectable({
    providedIn: 'root',
})
export class EventFieldService extends BaseService<IEventField> {
    constructor(public http: HttpClient) {
        super('/event-fields', http, null, { entityName: 'Event Field' });
    }
}
