import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    inject,
    OnInit,
} from '@angular/core';
import { provideTranslocoScope, TranslocoService } from '@jsverse/transloco';
import { CustomFormControlComponentBase } from '@mt-ng2/dynamic-form';

@Component({
    styles: [
        `
            .input-group {
                width: 100% !important;
            }
            .input-group > input.form-control {
                width: 100% !important;
            }
            .inside-box-validation {
                position: relative !important;
                top: -35px;
                bottom: -35px;
                margin-bottom: -35px;
                right: 3px;
                float: right;
                font-size: 70%;
            }
            .inside-box-maxlength {
                position: relative !important;
                bottom: -13px;
                top: -13px;
                margin-bottom: -13px;
                right: 3px;
                font-size: 70%;
            }
            .custom-url-label {
                display: inline-block;
                max-width: 100%;
                margin-bottom: 5px;
                font-weight: 700;
            }
            :host .ng-star-inserted {
                margin-bottom: 15px !important;
            }
        `,
    ],
    templateUrl: './google-autocomplete-input.component.html',
    providers: [
        provideTranslocoScope(
            {
                scope: 'event-venues/form',
                alias: 'evf',
            }
        ),
    ],
})
export class GoogleAutocompleteInputComponent extends CustomFormControlComponentBase implements OnInit, AfterViewInit {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private autocomplete: any;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    private translocoService = inject(TranslocoService);

    constructor(changeDetectorRef: ChangeDetectorRef) {
        super(changeDetectorRef);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.subscribeToStatusChanges();
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.initializeAutocomplete();
    }

    subscribeToStatusChanges(): void {
        this.subscriptions.add(
            this.getControl().statusChanges.subscribe((status) => {
                this.inputElement.nativeElement.disabled = status === 'DISABLED';
            }),
        );
    }

    initializeAutocomplete(): void {
        this.autocomplete = new google.maps.places.Autocomplete(this.inputElement.nativeElement as HTMLInputElement, {
            types: ['address']
        });

        this.autocomplete.addListener('place_changed', () => {
            this.setFieldValue();
        });
    }

    setFieldValue(): void {
        const control = this.getControl();
        const value = this.inputElement.nativeElement.value;
        const place = this.autocomplete.getPlace();
        if ((control.value !== value && place) || (value === '')) {
            control.setValue(value === '' ? null : place);
            control.markAsDirty();
        }
    }
}

