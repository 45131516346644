import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { IDisplayTimer, TimerService } from '../../../displays/services/timer.service';
import { TimerEvent, TimerProgress } from '../timer/timer.component';


@Component({
    selector: 'app-timer-countdown',
    templateUrl: './timer-countdown.component.html',
})
export class TimerCountdownComponent implements OnInit, OnDestroy {
    @Input() entityId: string;
    @Input() totalDisplayTime: number;
    @Output() progressUpdate = new EventEmitter<TimerProgress>();
    @Output() timerStarted = new EventEmitter<TimerEvent>();
    @Output() timerStopped = new EventEmitter<TimerEvent>();
    @Output() timerResumed = new EventEmitter<TimerEvent>();
    @Output() timerEndedEarly = new EventEmitter<TimerEvent>();
    @Output() timerReset = new EventEmitter<TimerEvent>();
    @Output() timerComplete = new EventEmitter<TimerEvent>();

    timer: IDisplayTimer;
    private timerSub: Subscription;
    private intervalSub: Subscription;

    constructor(private timerService: TimerService) { }

    ngOnInit() {
        this.timerSub = this.timerService.timerChanges(this.entityId).subscribe(timer => {
            this.timer = timer;
        });
    }

    ngOnDestroy() {
        this.timerSub?.unsubscribe();
        this.intervalSub?.unsubscribe();
    }

    onProgressUpdate(event: TimerProgress): void {
        this.progressUpdate.emit(event);
    }

    onTimerStarted(event: TimerEvent): void {
        this.timerStarted.emit(event);
    }

    onTimerStopped(event: TimerEvent): void {
        this.timerStopped.emit(event);
    }

    onTimerResumed(event: TimerEvent): void {
        this.timerResumed.emit(event);
    }

    onTimerEndedEarly(event: TimerEvent): void {
        this.timerEndedEarly.emit(event);
    }

    onTimerReset(event: TimerEvent): void {
        this.timerReset.emit(event);
    }

    onTimerComplete(event: TimerEvent): void {
        this.timerComplete.emit(event);
    }
}
