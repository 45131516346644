<div class="screen-header">
    <div class="screen-header-item-left">
        <div class="screen-header-item-left-event-name">{{eventFieldName}}</div>
    </div>
    <div class="screen-header-item-right">
        <img src="{{ logoFull }}" alt="RECF Logo" class="screen-header-item-right-logo">
        <img [src]="programImagePath" class="screen-header-item-right-logo" alt="Profile Image"/>
    </div>
</div>
<div class="screen-content">
    <div *ngIf="!loading" class="fullscreen-overlay">
        <div class="content-container">
            <!-- Headers -->
            <div *ngIf="!timerIsComplete && selectedEventSkillsChallengeTypeDisplay" class="header-section">
                <div class="header-section-label">
                    <h2 class="qualification-header">{{selectedEventSkillsChallengeTypeDisplay}}</h2>
                </div>
            </div>

            <div *ngIf="timerIsComplete" class="complete-container">
                <div class="complete-text">
                    {{ 'tp.scd.waiting' | transloco }}
                </div>
            </div>

            <!-- Race Countdown Display -->
            <div *ngIf="currentTimer?.timerState !== null && !timerIsComplete && showRaceCountdown" class="timer-container">
                <div class="timer-container-content">
                    <app-race-countdown
                        #raceCountdown
                        [lightInterval]="800"
                        beginText="{{ 'tp.BEGIN!' | transloco }}"
                        startButtonText="Start Race"
                        [showStartButton]="false"
                        lightActiveColor="#34C759"
                        [beginTextDuration]="2500"
                        (countdownFinished)="raceCountdownOnFinish()">
                    </app-race-countdown>
                </div>
            </div>

            <!-- Timer Display -->
            <div *ngIf="hasActiveTimer && !timerIsComplete && !showRaceCountdown" class="timer-container">
                <div [ngClass]="lessThanTenSeconds ? 'timer-text-red' : 'timer-text-white'" class="timer-container-content">
                    <app-timer
                        [timer]="currentTimer"
                        [totalDisplayTime]="durationMilliseconds"
                        (progressUpdate)="onProgressUpdate($event)"
                        (timerComplete)="onTimerComplete($event)"
                        (timerStopped)="onTimerStopped($event)"
                        (timerReset)="onTimerReset($event)">
                    </app-timer>
                </div>
            </div>

            <div *ngIf="!hasActiveTimer && !timerIsComplete && !showRaceCountdown" class="timer-container">
                <div class="timer-text-white timer-container-content">
                    {{ 'tp.scd.waiting' | transloco }}
                </div>
            </div>

            <div class="score-container">
                <div class="score-box red">
                    <span class="score-text">{{teamNumber}}</span>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="loading" class="fullscreen-overlay">
        <app-tournament-loading
            [size]="100"
            [borderWidth]="10">
        </app-tournament-loading>
    </div>
</div>
