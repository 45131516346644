<div class="screen-header">
    <div class="screen-header-item-left">
        <div class="screen-header-item-left-event-name">{{ event?.Name }}</div>
    </div>
    <div class="screen-header-item-right">
        <img src="{{ logoFull }}" alt="RECF Logo" class="screen-header-item-right-logo">
        <img [src]="programImagePath" class="screen-header-item-right-logo" alt="Profile Image"/>
    </div>
</div>

<div class="screen-content">
    <div *ngIf="!loading" class="fullscreen-overlay">
        <div class="content-container">            
            <div *ngIf="timerIsComplete" class="complete-container">
                <div class="complete-text">
                    {{ 'tp.td.Timer Display Complete' | transloco }}
                </div>
            </div>
    
            <!-- Race Countdown Display -->
            <div *ngIf="timerState !== null && !timerIsComplete && showRaceCountdown" class="timer-container">
                <div class="timer-container-content">
                    <app-race-countdown
                        #raceCountdown
                        [lightInterval]="800"
                        beginText="{{ 'tp.BEGIN!' | transloco }}"
                        [showStartButton]="false"
                        lightActiveColor="#34C759"
                        [beginTextDuration]="2000"
                        (countdownFinished)="raceCountdownOnFinish()">
                    </app-race-countdown>
                </div>
            </div>

            <!-- Timer Display -->
            <div *ngIf="timerState !== null && !timerIsComplete && !showRaceCountdown" class="timer-container">
                <div [ngClass]="lessThanTenSeconds ? 'timer-text-red' : 'timer-text-white'" class="timer-container-content">
                    <app-timer-countdown
                        [entityId]="entityId"
                        [totalDisplayTime]="durationMilliseconds"
                        (progressUpdate)="onProgressUpdate($event)"
                        (timerComplete)="onTimerComplete($event)"
                        (timerStopped)="onTimerStopped($event)"
                        (timerReset)="onTimerReset($event)">
                    </app-timer-countdown>
                </div>
            </div>

            <div *ngIf="timerState === null && !timerIsComplete && !showRaceCountdown && durationMilliseconds" class="timer-container">
                <div class="timer-text-white timer-container-content">
                    {{ formatTime(durationMilliseconds) }}
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="loading" class="fullscreen-overlay">
    <app-loading
        [size]="100"
        [borderWidth]="10">
    </app-loading>
</div>
