export enum ClaimTypes {
    Administration = 1,
    Users = 2,
    UserRoles = 3,
    Audit = 4,
    AccessRequests = 5,
    Programs = 6,
    EventRegions = 7,
    Seasons = 8,
    Teams = 9,
    Organizations = 10,
    CanRegisterOutsideRegistrationDates = 11,
    LevelClasses = 12,
    QualificationLevels = 13,
    CanViewOtherTeams = 14,
    Events = 15,
    CanEditTeamOrganizationAndNumber = 16,
    CanSetPrimaryCoach = 17,
    CanCreateEvents = 18,
    CanMoveTeamsToADifferentContact = 19,
    CanViewOtherEvents = 20,
    TournamentPortal = 21,
    TeamworkMatchScoring = 22,
    SkillsMatchScoring = 23,
    FieldControl = 24,
    AllianceSelection = 25,
    TeamCheckIn = 26,
    TeamInspection = 27,
    EnteringAwardWinners = 28,
    CanViewBackgroundCheck = 29,
    CanViewExpirationDate = 30,
    EventSkillsChallenges = 31,
    Settings = 32,
    Awards = 33,
}
