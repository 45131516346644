import { Signal } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';
import { IDragDropConfig } from '../extended-entity-list.config';

export class EntityListDragDropHelper {
    private draggedItem: IEntity | null = null;

    constructor(
        private config: IDragDropConfig,
        private items: Signal<IEntity[]>
    ) {}

    showDragHandle(printMode: boolean): boolean {
        return this.config.enabled && 
               !!this.config.dragHandle && 
               !printMode;
    }

    isDraggable(): boolean {
        return this.config.enabled;
    }

    getDragItemClass(): string {
        return this.config.dragItemClass || 'draggable-item';
    }

    getDropZoneClass(): string {
        return this.config.dropZoneClass || 'drop-zone';
    }

    handleDragStart(item: IEntity, event: DragEvent): void {
        this.draggedItem = item;
        if (event.dataTransfer) {
            event.dataTransfer.effectAllowed = 'move';
            event.dataTransfer.setData('text/plain', item.Id.toString());
        }
    }

    handleDragOver(event: DragEvent): void {
        if (event.preventDefault) {
            event.preventDefault();
        }
        if (event.dataTransfer) {
            event.dataTransfer.dropEffect = 'move';
        }
    }

    handleDragEnter(event: DragEvent): void {
        const element = event.currentTarget as HTMLElement;
        if (element && this.config.dropZoneClass) {
            element.classList.add(this.config.dropZoneClass);
        }
    }

    handleDragLeave(event: DragEvent): void {
        const element = event.currentTarget as HTMLElement;
        if (element && this.config.dropZoneClass) {
            element.classList.remove(this.config.dropZoneClass);
        }
    }

    handleDrop(event: DragEvent, targetItem: IEntity): { sourceItem: IEntity; targetItem: IEntity; newIndex: number } | null {
        event.preventDefault();
        
        if (!this.draggedItem || this.draggedItem === targetItem) {
            return null;
        }

        const currentItems = this.items();
        const targetIndex = currentItems.findIndex(item => item === targetItem);
        
        const element = event.currentTarget as HTMLElement;
        if (element && this.config.dropZoneClass) {
            element.classList.remove(this.config.dropZoneClass);
        }

        return {
            sourceItem: this.draggedItem,
            targetItem,
            newIndex: targetIndex
        };
    }

    handleDragEnd(): void {
        this.draggedItem = null;
    }
}