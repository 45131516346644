<div class="screen-header">
    <div class="screen-header-item-left">
       <div class="screen-header-item-left-event-name">{{eventName}}</div>
       <div class="screen-header-item-left-screen-title">{{ 'e.MatchSchedule' | transloco }}</div>
    </div>
    <div class="screen-header-item-right">
        <img src="{{ logoFull }}" alt="RECF Logo" class="screen-header-item-right-logo">
        <img [src]="programImagePath" class="screen-header-item-right-logo" alt="Profile Image"/>
    </div>
</div>
<div class="screen-content">
    <div *ngIf="!displayLogo" [@listAnimation]="listCount">
        <table class="match-number-table">
            <thead>
            <tr [style.height.px]="divHeightHeader" class="screen-content-header">
                <th class="screen-content-header-column-default">{{ 'e.MatchNumber' | transloco }}</th>
                <th class="screen-content-header-column-teams">{{ 'e.Teams' | transloco }}</th>
                <th class="screen-content-header-column-default">{{ 'e.Field' | transloco }}</th>
                <th class="screen-content-header-column-default format-time format-time-title">{{ 'e.Time' | transloco }}</th>
            </tr>
            </thead>
            <tbody>
                <tr *ngFor="let eventMatch of eventMatchLists" [style.height.px]="divHeightRow"  [ngClass]="{'canceled-match': eventMatch.EventMatchStatusId === canceledMatch}">
                    <td class="screen-content-row-column-default">{{ eventMatch.MatchNumber }}</td>
                    <td class="screen-content-row-column-teams" [innerHTML]="getTeamMatch(eventMatch)"></td>
                    <td class="screen-content-row-column-default">{{ eventMatch.FieldName }}</td>
                    <td class="screen-content-row-column-default format-time format-time-content">{{ getMatchTime(eventMatch) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="displayLogo" class="sponsors-list" [@listAnimation]="eventSponsorImagesToDisplay.length">
        <div class="col-xs-3 sponsors-logo" *ngFor="let image of eventSponsorImagesToDisplay">
            <img [src]="image" alt="Sponsor Image"/>
        </div>
    </div>
</div>
