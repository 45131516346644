import { Component, Input, OnDestroy, Output, EventEmitter, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { IDisplayTimer } from '../../../displays/services/timer.service';
import { formatTime } from '@common/libraries/timezone.library';

export interface TimerProgress {
    entityId: string;
    remainingMs: number;
    displayTime: string;
    percentage: number;
}

export interface TimerEvent {
    entityId: string;
    timer: IDisplayTimer;
}

export enum TimerStatus {
    Pending,
    StartCountdown,
    Active,
    Ended
}

export enum TimerState {
    Started,
    Stopped,
    Resumed,
    EndedEarly,
    Reset,
}

@Component({
    selector: 'app-timer',
    templateUrl: './timer.component.html',
})
export class TimerComponent implements OnInit, OnDestroy {
    @Input() set timer(value: IDisplayTimer) {
        if (value) {
            this.updateTimer(value);
        }
    };
    @Input() totalDisplayTime: number;
    @Output() progressUpdate = new EventEmitter<TimerProgress>();
    @Output() timerStarted = new EventEmitter<TimerEvent>();
    @Output() timerStopped = new EventEmitter<TimerEvent>();
    @Output() timerResumed = new EventEmitter<TimerEvent>();
    @Output() timerEndedEarly = new EventEmitter<TimerEvent>();
    @Output() timerReset = new EventEmitter<TimerEvent>();
    @Output() timerComplete = new EventEmitter<TimerEvent>();

    _timer: IDisplayTimer;
    private timerSub: Subscription;
    private intervalSub: Subscription;
    private hasEmittedCompletion = false;

    timeLeft: { minutes: number, seconds: number };
    startBufferTimeLeft: number;
    timerStatus: TimerStatus = TimerStatus.Pending;
    timerStatuses = TimerStatus;
    timerStates = TimerState;

    ngOnInit() {
        this.formatTimeLeft(this.totalDisplayTime, this._timer);
    }

    ngOnDestroy() {
        this.timerSub?.unsubscribe();
        this.intervalSub?.unsubscribe();
    }

    private updateTimer(timer: IDisplayTimer) {
        const updateTimerState = (timer: IDisplayTimer) => {
            this.emitProgress(timer.durationMilliseconds, timer.durationMilliseconds, timer.startDurationMilliseconds);
            this.formatTimeLeft(timer.durationMilliseconds, timer);
        };

        switch(timer?.timerState) {
            case TimerState.Started: {
                this.timerStarted.emit({ entityId: timer.entityId, timer });
                break;
            }
            case TimerState.Stopped: {
                updateTimerState(timer);
                this.timerStopped.emit({ entityId: timer.entityId, timer });
                break;
            }
            case TimerState.Resumed: {
                updateTimerState(timer);
                this.timerResumed.emit({ entityId: timer.entityId, timer });
                break;
            }
            case TimerState.EndedEarly: {
                updateTimerState(timer);
                this.timerEndedEarly.emit({ entityId: timer.entityId, timer });
                break;
            }
            case TimerState.Reset: {
                updateTimerState(timer);
                this.timerReset.emit({ entityId: timer.entityId, timer });
                break;
            }
        }

        this._timer = timer;
        if (timer) {
            this.hasEmittedCompletion = false;
            this.startCountdown();
        } else {
            this.stopCountdown();
        }
    }

    private startCountdown() {
        this.intervalSub?.unsubscribe();
        this.intervalSub = interval(100).subscribe(() => {
            if (!this._timer || this._timer.isPaused || this._timer.timerState === TimerState.Stopped) {
                return;
            }

            const now = new Date().getTime();
            const end = new Date(this._timer.endTime).getTime();
            const timeLeft = end - now;
            const totalTime = this._timer.durationMilliseconds;

            if (timeLeft <= 0) {
                this.formatTimeLeft(0, this._timer);
                this.emitProgress(0, 0, totalTime);
                if (!this.hasEmittedCompletion) {
                    this.timerComplete.emit({ entityId: this._timer.entityId, timer: this._timer });
                    this.hasEmittedCompletion = true;
                }
                this.intervalSub?.unsubscribe();
                return;
            }

            this.formatTimeLeft(timeLeft, this._timer);
            if (this._timer.timerState === TimerState.Resumed) {
                this.emitProgress(totalTime, timeLeft, this._timer.startDurationMilliseconds);
            } else {
                this.emitProgress(this.totalDisplayTime, timeLeft, totalTime);
            }
        });
    }

    private stopCountdown() {
        this.intervalSub?.unsubscribe();
    }

    formatTimeLeft(timeLeftMilliseconds: number, displayTimer: IDisplayTimer): void {
        const durationMill = displayTimer?.durationMilliseconds ?? 0;
        const startBufferMill = displayTimer?.startBufferMilliseconds ?? 0;
        const totalDurationWithBuffer = this.roundToClosestSecond(durationMill + startBufferMill);

        if (timeLeftMilliseconds > 0 && totalDurationWithBuffer <= 0) {
            this.setTimeLeftFromMilliseconds(timeLeftMilliseconds);
            this.timerStatus = TimerStatus.Pending;
        } else if (timeLeftMilliseconds > this.roundToClosestSecond(durationMill)) {
            this.timerStatus = TimerStatus.StartCountdown;
            this.setStartBufferTimeLeft(timeLeftMilliseconds, durationMill);
        } else {
            this.setTimeLeftFromMilliseconds(timeLeftMilliseconds);
            this.timerStatus = TimerStatus.Active;
        }
    }

    roundToClosestSecond(timeMilliseconds: number): number {
        return Math.ceil(timeMilliseconds / 1000) * 1000;
    }

    setStartBufferTimeLeft(timeLeftMilliseconds: number, durationMilliseconds: number): void {
        this.startBufferTimeLeft = Math.floor((timeLeftMilliseconds - durationMilliseconds) / 1000 % 60);
    }

    setTimeLeftFromMilliseconds(timeLeftMilliseconds: number): void {
        this.timeLeft = {
            minutes: Math.floor((timeLeftMilliseconds) / 1000 / 60 % 60),
            seconds: Math.floor((timeLeftMilliseconds) / 1000 % 60)
        };
    }

    private emitProgress(milliseconds: number, remainingMs: number, totalMs: number) {
        const percentage = totalMs > 0 ? (remainingMs / totalMs) * 100 : 0;
        const displayTime = this.formatTime(milliseconds);
        this.progressUpdate.emit({
            entityId: this._timer?.entityId,
            remainingMs: remainingMs,
            displayTime: displayTime,
            percentage
        });
    }

    formatTime(milliseconds: number): string {
        return formatTime(milliseconds);
    }
}
