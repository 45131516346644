import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IEvent } from '../../../model/core/interfaces/event';
import { Observable } from 'rxjs';

export const emptyEvent: IEvent = {
    Name: '',
    SeasonId: 0,
    EventTypeId: 0,
    EventStatusId: 0,
    CreatedById: 0,
    Code: '',
    Subtitle: '',
    Timezone: '',
    StartDate: new Date(),
    EndDate: new Date(),
    ReleaseDate: new Date(),
    RegistrationStartDate: new Date(),
    RegistrationEndDate: new Date(),
    DateCreated: new Date(),
    Archived: false,
    EventNumber: 0,
    Id: 0
};

@Injectable({
    providedIn: 'root',
})
export class EventService extends BaseService<IEvent> {
    constructor(public http: HttpClient) {
        super('/events', http, [], { entityName: 'Event' });
    }

    formatTitleText(entity: IEvent): void {
        this.setTitle(`${entity.Name}`);
    }

    getEmptyEvent(): IEvent {
        return { ...emptyEvent };
    }

    determineExtraMatchesForTeams(eventId: number, matchId: number): Observable<Record<number, boolean>> {
        return this.http.post<Record<number, boolean>>(`/event-matches/determine-extra-matches-for-teams`, { eventId: eventId, matchId: matchId});
    }
}