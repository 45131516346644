import { Injectable } from '@angular/core';
import { Observable, from, BehaviorSubject, tap, map } from 'rxjs';
import { SignalRService } from './signal-r.service';
import { HubConnection } from '@microsoft/signalr';
import { IDisplayTimer } from './timer.service';

export interface ISkillsChallengeTimer extends IDisplayTimer {
    eventId: number,
    eventFieldId: number,
}

export interface IStartSkillsChallengeTimerParams {
    EventSkillsChallengeId: number,
    StartBufferMilliseconds: number,
    DurationMilliseconds: number,
    isPaused: boolean,
}

@Injectable({
    providedIn: 'root',
})
export class SkillsChallengeTimerService {
    START_BUFFER_MILLISECONDS = 5000;
    private activeTimers: BehaviorSubject<Map<string, ISkillsChallengeTimer>> = new BehaviorSubject<Map<string, ISkillsChallengeTimer>>(new Map<string, ISkillsChallengeTimer>());
    private connection: HubConnection;

    constructor(private signalRService: SignalRService) {}

    fieldTimerChanges(eventId: number, eventFieldId: number): Observable<ISkillsChallengeTimer[]> {
        return this.activeTimers.pipe(
            map((timers) => [...timers.values()]
                .filter((timer) => timer.eventId === eventId && timer.eventFieldId == eventFieldId)
                .map((timer) => timer)
            )
        );
    }

    // Get timer observable for specific entity
    timerChanges(entityId: number): Observable<ISkillsChallengeTimer> {
        return this.activeTimers.pipe(
            map((timers) => [...timers.values()]
                .find((timer) => timer.entityId === entityId.toString())
            )
        );
    }

    connectTimerDisplay(eventId: number, eventFieldId: number): Observable<HubConnection> {
        return this.signalRService.startConnection(`/hub/skills-challenge-display?eventId=${eventId}&fieldId=${eventFieldId}`)
            .pipe(
                tap((connection) => {
                    this.connection = connection;
                    this.initializeListeners();
                })
            );
    }

    tryGetSkillsChallengeTimer(entityId: number): void {
        this.connection.invoke("TryGetSkillsChallengeTimer", entityId)
            .catch((err) => console.error(err.toString()));
    }

    tryGetFieldTimer(eventId: number, eventFieldId: number): void {
        this.connection.invoke("TryGetFieldTimer", eventId, eventFieldId)
            .catch((err) => console.error(err.toString()));
    }

    disconnectTimerDisplay(): void {
        this.signalRService.stopConnection(this.connection);
    }

    initializeListeners(): void {
        this.connection.on("StartTimer", (timer: ISkillsChallengeTimer) => {
            timer.endTime = new Date(timer.endTime);
            this.setTimer(timer);
        });

        this.connection.on("StopTimer", (timer: ISkillsChallengeTimer) => {
            timer.endTime = new Date(timer.endTime);
            this.setTimer(timer);
        });

        this.connection.on("ResumeTimer", (timer: ISkillsChallengeTimer) => {
            timer.endTime = new Date(timer.endTime);
            this.setTimer(timer);
        });

        this.connection.on("RemoveTimer", (timer: ISkillsChallengeTimer) => {
            this.setTimer({ ...timer, entityId: timer.entityId });
        });

        this.connection.on("ResetTimer", (timer: ISkillsChallengeTimer) => {
            this.setTimer({ ...timer, entityId: timer.entityId });
        });

        this.connection.on("EndTimerEarly", (timer: ISkillsChallengeTimer) => {
            this.setTimer({ ...timer, entityId: timer.entityId });
        });
    }

    setTimer(timer: ISkillsChallengeTimer | undefined): void {
        const timers = this.activeTimers.getValue();
        timers.set(timer.entityId, timer);

        this.activeTimers.next(timers);
    }

    getTimer(entityId: number): ISkillsChallengeTimer | undefined {
        return this.activeTimers.getValue().get(entityId.toString());
    }

    start(entityId: number, durationMilliseconds: number, isPaused: boolean): Observable<void> {
        const startParams: IStartSkillsChallengeTimerParams = {
            EventSkillsChallengeId: entityId,
            StartBufferMilliseconds: this.START_BUFFER_MILLISECONDS,
            DurationMilliseconds: durationMilliseconds,
            isPaused: isPaused
        };
        return from(this.connection.invoke("BroadcastStartTimer", startParams));
    }

    stop(entityId: number): Observable<void> {
        const timer: ISkillsChallengeTimer = this.getTimer(entityId);
        return from(this.connection.invoke("BroadcastStopTimer", timer.timerId, entityId));
    }

    remove(entityId: number): Observable<void> {
        const timer: ISkillsChallengeTimer = this.getTimer(entityId);
        return from(this.connection.invoke("BroadcastRemoveTimer", timer.timerId, entityId));
    }

    reset(entityId: number): Observable<void> {
        const timer: ISkillsChallengeTimer = this.getTimer(entityId);
        return from(this.connection.invoke("BroadcastResetTimer", timer.timerId, entityId));
    }

    resume(entityId: number): Observable<void> {
        const timer: ISkillsChallengeTimer = this.getTimer(entityId);
        return from(this.connection.invoke("BroadcastResumeTimer", timer.timerId, entityId));
    }

    endEarly(entityId: number): Observable<void> {
        const timer: ISkillsChallengeTimer = this.getTimer(entityId);
        return from(this.connection.invoke("BroadcastEndTimerEarly", timer.timerId, entityId));
    }

    removeLocalTimer(entityId: string): void {
        const timers = this.activeTimers.getValue();
        timers.delete(entityId);

        this.activeTimers.next(timers);
    }
}
