import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BackButtonModule } from '@mt-ng2/back-button-module';
import { MtDisableDuringHttpCallsModule } from '@mt-ng2/disable-during-http-calls';
import { MtDocumentControlModule } from '@mt-ng2/document-control';
import { DynamicFormModule } from '@mt-ng2/dynamic-form';
import { DynamicSearchFiltersModule } from '@mt-ng2/dynamic-search-filters';
import { EntityComponentsAddressesModule } from '@mt-ng2/entity-components-addresses';
import { EntityComponentsDocumentsModule } from '@mt-ng2/entity-components-documents';
import { EntityListModule, IEntityListModuleConfig } from '@mt-ng2/entity-list-module';
import { KeyboardShortcutModule } from '@mt-ng2/keyboard-shortcuts-module';
import { ModalModule } from '@mt-ng2/modal-module';
import { NavModule } from '@mt-ng2/nav-module';
import { TypeAheadModule } from '@mt-ng2/type-ahead-control';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { TimerCountdownComponent } from './components/timer-countdown/timer-countdown.component';
import { RaceCountdownComponent } from './components/race-countdown/race-countdown.component';
import { LoadingComponent } from './components/loading/loading.component';
import { TimerComponent } from './components/timer/timer.component';

export const entityListModuleConfig: IEntityListModuleConfig = {
    itemsPerPage: 10,
};

/**
 * Shared Module contains all imports and exports that are commonly used in all other modules.
 * Since this is imported into the AppModule, only things that are needed on startup should be
 * included.
 *
 * Is imported into nearly all other modules, including the root module "AppModule".  forRoot
 * is only called from AppModule.
 *
 * Tip: If the import/export should only be included in feature modules, than consider putting
 * it into "AdminPortalFeatureModule" instead.  If the import/export is only needed at the AppModule level
 * and rarely/never needs to be imported into any other modules, than consider putting it into
 * "AppModule" instead.
 */

@NgModule({
    declarations: [
        TimerCountdownComponent,
        RaceCountdownComponent,
        LoadingComponent,
        TimerComponent
    ],
    exports: [
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule,
        ModalModule,
        NavModule,
        MtDisableDuringHttpCallsModule,
        BackButtonModule,
        KeyboardShortcutModule,
        DynamicSearchFiltersModule,
        EntityComponentsDocumentsModule,
        MtDocumentControlModule,
        DynamicFormModule,
        EntityListModule,
        TypeAheadModule,
        EntityComponentsAddressesModule,
        TimerCountdownComponent,
        RaceCountdownComponent,
        LoadingComponent,
        TimerComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule,
        ModalModule,
        NavModule,
        MtDisableDuringHttpCallsModule,
        BackButtonModule,
        KeyboardShortcutModule,
        DynamicSearchFiltersModule,
        EntityComponentsDocumentsModule,
        MtDocumentControlModule,
        DynamicFormModule,
        EntityListModule,
        TypeAheadModule,
        EntityComponentsAddressesModule,
    ],
})
export class TournamentPortalSharedModule {
    static forRoot(): ModuleWithProviders<TournamentPortalSharedModule> {
        return {
            ngModule: TournamentPortalSharedModule,
            providers: [],
        };
    }
}
