<div class="table-responsive">
    <table class="table table-hover table-striped" [ngClass]="entityListConfig.hasTableClass ? entityListConfig.tableClass : null">
        <thead>
            <tr *ngIf="!headerTemplate && entityListConfig">
                <th *ngIf="dragDropHelper" style="width: 80px">
                    {{ 'c.actions' | transloco }}
                </th>
                <th *ngIf="dragDropHelper?.showDragHandle(printMode)" style="width: 30px">
                </th>
                <th
                    *ngIf="selectHelper?.showSelect(printMode)"
                    [style.width.px]="selectHelper?.selectConfig?.width"
                    (click)="selectAllClicked($event)"
                >
                    <i
                        *ngIf="selectHelper?.isMultiSelect()"
                        class="fa fa-lg fa-fw"
                        aria-hidden="true"
                        [class.fa-check-square-o]="items().length && selectHelper?.isAllItemsSelected(items())"
                        [class.fa-square-o]="!items().length || !selectHelper?.isAllItemsSelected(items())"
                    ></i>
                </th>
                <ng-container *ngFor="let column of columns">
                    <th
                        *ngIf="column.showColumn(printMode)"
                        [style.width.px]="column.style ? column.style.width : null"
                        [ngStyle]="{
                            cursor: column.sort.disableSort ? 'auto' : 'pointer'
                        }"
                        [ngClass]="column.columnHeaderClass ? column.columnHeaderClass : null"
                        (click)="columnSorted(column, $event)"
                    >
                        <b
                            >{{ column.name }}
                            <span ngPreserveWhitespaces *ngIf="column.sort && !column.sort.disableSort"
                                ><i
                                    [style.opacity]="column.sort.direction === sortEnum.Asc ? null : 0.4"
                                    class="fa fa-sort-asc"
                                    aria-hidden="true"
                                ></i>
                                <i
                                    [style.opacity]="column.sort.direction === sortEnum.Desc ? null : 0.4"
                                    style="margin-left: -12px"
                                    class="fa fa-sort-desc"
                                    aria-hidden="true"
                                ></i
                            ></span>
                        </b>
                    </th>
                </ng-container>
                <th *ngIf="showDelete()" [style.width.px]="entityListConfigDelete?.width">
                    <b>{{ entityListConfigDelete?.headerText }}</b>
                </th>
            </tr>
            <tr *ngIf="headerTemplate" class="no-pointer">
                <ng-template [ngTemplateOutlet]="headerTemplate"> </ng-template>
            </tr>
        </thead>
        <tbody *ngIf="!itemTemplate && entityListConfig">
            <tr
                *ngFor="let entity of items(); let first = first; let last = last"
                [draggable]="dragDropHelper?.isDraggable()"
                (dragstart)="onDragStart(entity, $event)"
                (dragover)="onDragOver($event)"
                (dragenter)="onDragEnter($event)"
                (dragleave)="onDragLeave($event)"
                (drop)="onDrop($event, entity)"
                (dragend)="onDragEnd()"
                (click)="!entityListConfig.preventRowSelection ? itemSelected(entity, $event) : null"
                [ngClass]="getRowClass(entity)"
            >
            <td *ngIf="dragDropHelper" class="text-nowrap">
                <button
                    *ngIf="!first"
                    type="button"
                    class="btn btn-sm btn-link"
                    title="Move to Top"
                    [title]="'c.moveToTop' | transloco"
                    (click)="moveToTopEvent.emit({ item: entity, currentItems: items() }); $event.stopPropagation()">
                    <i class="fa fa-lg fa-angle-double-up"></i>
                </button>
                <button
                    *ngIf="!last"
                    type="button"
                    class="btn btn-sm btn-link"
                    [title]="'c.moveToBottom' | transloco"
                    (click)="moveToBottomEvent.emit({ item: entity, currentItems: items() }); $event.stopPropagation()">
                    <i class="fa fa-lg fa-angle-double-down"></i>
                </button>
            </td>
                <td *ngIf="dragDropHelper?.showDragHandle(printMode)"
                    class="drag-handle"
                    style="width: 30px; cursor: move;">
                    <i class="fa fa-lg fa-bars fa-fw"></i>
                </td>
                <td
                    *ngIf="selectHelper?.showSelect(printMode)"
                    class="ellipsis"
                    [style.background-color]="selectHelper?.isItemSelected(entity) ? selectHelper?.selectConfig?.selectedColor : null"
                    [style.width.px]="selectHelper?.selectConfig?.width"
                >
                    <i
                        *ngIf="selectHelper?.isSelectable(entity)"
                        class="fa fa-lg fa-lg fa-fw"
                        aria-hidden="true"
                        [class.fa-check-square-o]="selectHelper?.isItemSelected(entity)"
                        [class.fa-square-o]="!selectHelper?.isItemSelected(entity)"
                    ></i>
                </td>
                <ng-container *ngFor="let column of columns">
                    <td
                        *ngIf="column.showColumn(printMode) && !column.component"
                        (click)="column.fireOnColumnSelected ? columnSelected(entity, column, $event) : null"
                        class="ellipsis"
                        [style.background-color]="selectHelper?.isItemSelected(entity) ? selectHelper?.selectConfig?.selectedColor : null"
                        [style.width.px]="column.style ? column.style.width : null"
                        [ngClass]="column.hasColumnClass ? column.getColumnClass(entity) : null"
                    >
                        <div
                            *ngIf="column.bindToInnerHtml"
                            [innerHTML]="column.getItemValue(entity, entityListPipeService, defaultDisplayValueWhenNull)"
                        ></div>
                        <a *ngIf="column.linkFunction" (click)="handleLinkFunction(entity, column, $event)" href="javascript:void(0);">{{
                            column.getItemValue(entity, entityListPipeService, defaultDisplayValueWhenNull)
                        }}</a>
                        <div *ngIf="!column.bindToInnerHtml && !column.linkFunction">
                            {{ column.getItemValue(entity, entityListPipeService, defaultDisplayValueWhenNull) }}
                        </div>
                    </td>
                    <td
                        *ngIf="column.showColumn(printMode) && column.component"
                        [style.background-color]="selectHelper?.isItemSelected(entity) ? selectHelper?.selectConfig?.selectedColor : null"
                        [style.width.px]="column.style ? column.style.width : null"
                        [ngClass]="column.hasColumnClass ? column.getColumnClass(entity) : null"
                    >
                    </td>
                </ng-container>
                <td
                    *ngIf="showDelete() && entityListConfigDelete?.confirm && !entityListConfigDelete?.component"
                    class="ellipsis"
                    [style.background-color]="selectHelper?.isItemSelected(entity) ? selectHelper?.selectConfig?.selectedColor : null"
                    [style.width.px]="entityListConfigDelete?.width"
                    (mtConfirm)="itemDeleted(entity, $event)"
                    [mtConfirmOptions]="entityListConfigDelete!.getConfirm(entity)"
                    [innerHtml]="entityListConfigDelete?.getColumnHtml(entity)"
                ></td>
                <td
                    *ngIf="showDelete() && !entityListConfigDelete?.confirm && !entityListConfigDelete?.component"
                    class="ellipsis"
                    [style.background-color]="selectHelper?.isItemSelected(entity) ? selectHelper?.selectConfig?.selectedColor : null"
                    [style.width.px]="entityListConfigDelete?.width"
                    (click)="itemDeleted(entity, $event)"
                    [innerHtml]="entityListConfigDelete?.getColumnHtml(entity)"
                ></td>
                <td
                    *ngIf="showDelete() && entityListConfigDelete?.component"
                    class="ellipsis"
                    [style.background-color]="selectHelper?.isItemSelected(entity) ? selectHelper?.selectConfig?.selectedColor : null"
                    [style.width.px]="entityListConfigDelete?.width"
                >
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="itemTemplate">
            <ng-container *ngFor="let entity of items()">
                <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: entity }"> </ng-template>
            </ng-container>
        </tbody>
    </table>
</div>
<div *ngIf="noEntities()">
    <h3 style="text-align: center;">{{ emptyMessage }}</h3>
</div>
<scroll-check (inViewHasChanged)="bottomOfListInViewChanged($event)" [offset]="getMoreConfig.bottomOfListOffset"></scroll-check>
