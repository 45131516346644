import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventMatchStatuses } from '@model/enums/event-match-statuses.enum';
import { IEventMatchDisplay } from '@model/interfaces/custom/event-match-display';
import { IEventMatchScreenPagination } from '@model/interfaces/custom/event-match-screen-pagination';
import { BaseService, LocalizeDatesByName } from '@mt-ng2/base-service';
import { Observable, tap } from 'rxjs';

import { IEventMatch } from '../../../model/core/interfaces/event-match';

export const emptyEventMatch: IEventMatch = {
    EventDivisionId: 0,
    EventMatchTypeId: 0,
    EventFieldId: 0,
    MatchNumber: ``,
    StartTime: new Date(),
    EndTime: new Date(),
    Id: 0,
    EventMatchStatusId: EventMatchStatuses.Scheduled,
};

@Injectable({
    providedIn: 'root',
})
export class EventMatchService extends BaseService<IEventMatch> {
    readonly UTC_DATE = 'UTC';
    readonly DATEFORMAT = 'yyyy-M-dd';
    constructor(public http: HttpClient) {
        super('/event-matches', http, ['StartTime', 'EndTime'], { entityName: 'EventMatch' });
    }

    formatTitleText(entity: IEventMatch): void {
        this.setTitle(`${entity.MatchNumber}`);
    }

    getEmptyEventMatch(): IEventMatch {
        return { ...emptyEventMatch };
    }

    determineExtraMatchesForTeams(eventId: number, matchId: number): Observable<Record<number, boolean>> {
        return this.http.post<Record<number, boolean>>(`/event-matches/determine-extra-matches-for-teams`, { eventId: eventId, matchId: matchId });
    }

    getNumberOfMatches(eventId: number) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.http.get<any>(`/event-matches/${eventId}/get-number-of-matches`);
    }

    getMatchesByEventId(data: IEventMatchScreenPagination): Observable<IEventMatchDisplay> {
        return this.http
            .post<IEventMatchDisplay>(`/event-matches/get-event-match`, data)
            .pipe(tap((response) => LocalizeDatesByName(response, ['EventMatchLists.MatchTime'])));
    }

    decreaseMatches(eventId: number, numberOfMatches: number) {
        const formData: FormData = new FormData();
        formData.append('numberOfMatches', JSON.stringify(numberOfMatches));
        return this.http.put<number>(`/event-matches/${eventId}/reduce-number-of-matches`, formData);
    }

    getMatchDate(eventId: number) {
        return this.http.get<string[]>(`/event-matches/${eventId}/get-match-date`);
    }
}
