import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IEvent } from '../../../model/core/interfaces/event';
import { Observable, Subject } from 'rxjs';
import { ICopyEventToSeasonDto } from '@model/interfaces/custom/copy-event-to-season-dto';

export const emptyEvent: IEvent = {
    Name: '',
    EventTypeId: 0,
    EventStatusId: 0,
    EventNumber: 0,
    Archived: false,
    Id: 0,
    SeasonId: 0,
    CreatedById: 0,
    Timezone: '',
    Code: '',
    Subtitle: '',
    StartDate: null,
    EndDate: null,
    ReleaseDate: null,
    RegistrationStartDate: null,
    RegistrationEndDate: null,
    DateCreated: null
};

@Injectable({
    providedIn: 'root',
})
export class EventService extends BaseService<IEvent> {
    constructor(public http: HttpClient) {
        super('/events', http, [], { entityName: 'Events' });
    }

    getEmptyEvent(): IEvent {
        return { ...emptyEvent };
    }

    copyEventToSeason(dto: ICopyEventToSeasonDto): Observable<number> {
        return this.http.post<number>(`/events/copy-to-season`, dto);
    }

    private refreshEventListSubject = new Subject<void>();
    refreshEventListSubject$ = this.refreshEventListSubject.asObservable();
    refreshEventList() {
        this.refreshEventListSubject.next();
    }

    private showCopyEventModalSubject = new Subject<IEvent>();
    showCopyEventModalSubject$ = this.showCopyEventModalSubject.asObservable();
    showCopyEventModal(event: IEvent) {
        this.showCopyEventModalSubject.next(event);
    }
}
