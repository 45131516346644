import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SoundService {
    private audioContext = new AudioContext();

    playBeep(frequency = 440, duration = 800, volume = 0.1) {
        const oscillator = this.audioContext.createOscillator();
        const gainNode = this.audioContext.createGain();

        oscillator.connect(gainNode);
        gainNode.connect(this.audioContext.destination);

        oscillator.frequency.value = frequency;
        gainNode.gain.value = volume;

        oscillator.start();

        setTimeout(() => {
            oscillator.stop();
            oscillator.disconnect();
        }, duration);
    }

    playError() {
        this.playBeep(220, 1000, 0.1);
    }

    playSuccess() {
        this.playBeep(880, 1200, 0.1);
    }

    playWarning() {
        this.playBeep(440, 2000, 0.1);
    }
}