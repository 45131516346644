<app-timer
    [timer]="timer"
    [totalDisplayTime]="totalDisplayTime"
    (progressUpdate)="onProgressUpdate($event)"
    (timerStarted)="onTimerStarted($event)"
    (timerStopped)="onTimerStopped($event)"
    (timerResumed)="onTimerResumed($event)"
    (timerEndedEarly)="onTimerEndedEarly($event)"
    (timerReset)="onTimerReset($event)"
    (timerComplete)="onTimerComplete($event)">
</app-timer>
