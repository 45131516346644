import { Injectable } from '@angular/core';
import { Observable, from, BehaviorSubject } from 'rxjs';
import { SignalRService } from './signal-r.service';
import { HubConnection } from '@microsoft/signalr';
import { TimerState } from '../../common/components/timer/timer.component';

export interface IDisplayTimer {
    timerId: number,
    entityId: string,
    endTime: Date,
    startBufferMilliseconds: number,
    durationMilliseconds: number,
    isPaused: boolean,
    isReset: boolean,
    startDurationMilliseconds: number,
    timerState: TimerState,
}

export interface IStartDisplayTimerParams {
    EntityId: string,
    StartBufferMilliseconds: number,
    DurationMilliseconds: number,
    isPaused: boolean,
}

@Injectable({
    providedIn: 'root',
})
export class TimerService {
    START_BUFFER_MILLISECONDS = 5000;
    // Change to store multiple timers, keyed by entityId
    private activeTimers: Map<string, BehaviorSubject<IDisplayTimer | undefined>> = new Map();
    private connection: HubConnection;

    constructor(private signalRService: SignalRService) {}

    // Get timer observable for specific entity
    timerChanges(entityId: string): Observable<IDisplayTimer | undefined> {
        if (!this.activeTimers.has(entityId)) {
            this.activeTimers.set(entityId, new BehaviorSubject<IDisplayTimer | undefined>(undefined));
        }
        return this.activeTimers.get(entityId).asObservable();
    }

    connectTimerDisplay(entityId: string): void {
        this.signalRService.startConnection('/hub/display')
            .subscribe((connection) => {
                this.connection = connection;
                this.initializeListeners();
                this.connection.invoke("TryGetTimer", entityId)
                    .catch((err) => console.error(err.toString()));
            });
    }

    disconnectTimerDisplay(): void {
        this.signalRService.stopConnection(this.connection);
    }

    initializeListeners(): void {
        this.connection.on("StartTimer", (timer: IDisplayTimer) => {
            timer.endTime = new Date(timer.endTime);
            this.setTimer(timer);
        });

        this.connection.on("StopTimer", (timer: IDisplayTimer) => {
            const currentTimer = this.activeTimers.get(timer.entityId)?.getValue();
            if (currentTimer?.timerId === timer.timerId) {
                timer.endTime = new Date(timer.endTime);
                this.setTimer(timer);
            }
        });

        this.connection.on("ResumeTimer", (timer: IDisplayTimer) => {
            const currentTimer = this.activeTimers.get(timer.entityId)?.getValue();
            if (currentTimer?.timerId === timer.timerId) {
                timer.endTime = new Date(timer.endTime);
                this.setTimer(timer);
            }
        });

        this.connection.on("RemoveTimer", (timer: IDisplayTimer) => {
            const currentTimer = this.activeTimers.get(timer.entityId)?.getValue();
            if (currentTimer?.timerId === timer.timerId) {
                this.setTimer({ ...timer, entityId: timer.entityId });
            }
        });

        this.connection.on("ResetTimer", (timer: IDisplayTimer) => {
            const currentTimer = this.activeTimers.get(timer.entityId)?.getValue();
            if (currentTimer?.timerId === timer.timerId) {
                this.setTimer({ ...timer, entityId: timer.entityId });
            }
        });

        this.connection.on("EndTimerEarly", (timer: IDisplayTimer) => {
            const currentTimer = this.activeTimers.get(timer.entityId)?.getValue();
            if (currentTimer?.timerId === timer.timerId) {
                this.setTimer({ ...timer, entityId: timer.entityId });
            }
        });
    }

    setTimer(timer: IDisplayTimer | undefined): void {
        if (!this.activeTimers.has(timer?.entityId)) {
            this.activeTimers.set(timer.entityId, new BehaviorSubject<IDisplayTimer | undefined>(undefined));
        }
        this.activeTimers.get(timer.entityId).next(timer);
    }

    start(entityId: string, durationMilliseconds: number, isPaused: boolean): Observable<void> {
        const startParams: IStartDisplayTimerParams = {
            EntityId: entityId,
            StartBufferMilliseconds: this.START_BUFFER_MILLISECONDS,
            DurationMilliseconds: durationMilliseconds,
            isPaused: isPaused
        };
        return from(this.connection.invoke("BroadcastStartTimer", startParams));
    }

    stop(entityId: string): Observable<void> {
        const timer: IDisplayTimer = this.activeTimers.get(entityId)?.getValue();
        return from(this.connection.invoke("BroadcastStopTimer", timer.timerId, entityId));
    }

    remove(entityId: string): Observable<void> {
        const timer: IDisplayTimer = this.activeTimers.get(entityId)?.getValue();
        return from(this.connection.invoke("BroadcastRemoveTimer", timer.timerId, entityId));
    }

    reset(entityId: string): Observable<void> {
        const timer: IDisplayTimer = this.activeTimers.get(entityId)?.getValue();
        return from(this.connection.invoke("BroadcastResetTimer", timer.timerId, entityId));
    }

    resume(entityId: string): Observable<void> {
        const timer: IDisplayTimer = this.activeTimers.get(entityId)?.getValue();
        return from(this.connection.invoke("BroadcastResumeTimer", timer.timerId, entityId));
    }

    endEarly(entityId: string): Observable<void> {
        const timer: IDisplayTimer = this.activeTimers.get(entityId)?.getValue();
        return from(this.connection.invoke("BroadcastEndTimerEarly", timer.timerId, entityId));
    }

    removeLocalTimer(entityId: string): void {
        this.activeTimers.delete(entityId);
    }
}
